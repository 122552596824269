<template>
  <div>
    {{ userTypeCompte.libelle }}
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: { type: Object, required: true}
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            typeComptes: 'auth/typeComptes'
        }),
        userTypeCompte(){
            return this.typeComptes.find(item => item.uid === this.object.typeCompte)
        }
    }
}
</script>